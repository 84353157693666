@import 'global.scss';

.header {
  display: flex;
  flex-direction: column;
  gap: $gap-horizontal;
  margin: 0 20px;
  margin-bottom: $corner-radius-large;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: $gap-horizontal;
    align-items: center;
  }

  @include desktop-max {
    margin-right: 0;
    margin-left: 0;
  }
}

.title {
  @include heading-h4;
  color: $color-neutral-gray;
  margin: 0;

  @include tablet {
    @include heading-h3;
  }
}

.swiper {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include desktop-max {
    margin-top: -20px;
  }

  :global(.swiper-wrapper) {
    align-items: center;
    height: 444px;
    @include desktop-max {
      box-sizing: border-box;
      margin: 10px 0;
      height: 484px;
    }
  }
  :global(.swiper-slide) {
    width: 310px !important;
    height: min-content;

    &:first-of-type {
      margin-left: 20px;
      @include desktop-max {
        margin-left: 0;
      }
    }
    &:last-of-type {
      margin-right: 20px;
      @include desktop-max {
        margin-right: 0;
      }
    }
  }
  :global(.swiper-pagination) {
    @include desktop-max {
      margin: 0 !important;
    }
  }

  :global(.swiper-button-next) {
    top: 194px !important;
    @include desktop-max {
      top: calc(50% - 8px) !important;
    }
  }
  :global(.swiper-button-prev) {
    top: 194px !important;
    @include desktop-max {
      top: calc(50% - 8px) !important;
    }
  }

  &__homeTab {
    :global(.swiper-wrapper) {
      align-items: center;
      height: 588px;
      @include desktop-max {
        height: 588px;
      }
    }

    :global(.swiper-button-next) {
      top: 245px !important;
      @include desktop-max {
        top: 264px !important;
      }
    }
    :global(.swiper-button-prev) {
      top: 245px !important;
      @include desktop-max {
        top: 264px !important;
      }
    }
  }
}
