@import 'global.scss';

.container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 12px 0 -8px;
  padding: 24px;
  border-radius: $corner-radius-large;
  background-color: $color-base-100;
  gap: 24px;

  @include tablet {
    margin: 0;
    margin-bottom: -24px;
  }

  @include desktop {
    margin: 0;
  }
}

.text {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}

.title {
  @include heading-h5;
  color: $color-base-900;

  @include tablet {
    @include heading-h4;
  }
}

.subtitle {
  @include paragraph-p5;
  color: $color-base-900;

  @include tablet { 
    @include paragraph-p4;
  }
}

.close {
  position: absolute;
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 22.5px;
  top: 27px;
  cursor: pointer;
}